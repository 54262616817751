import React from "react";
import Header from "../components/commen-components/header/header";
import Footer from "../components/footer/footer";

export default function RefundAndCancellation() {
    return (
        <>
            <Header />
            <section>
                <div class="container">
                    <div class="row page-body mb-5">
                        <div class="col-sm-12 col-md-12 col-lg-12 float-left padd-0 text-left mt-5">
                            <p class="about-header mb-3">
                                <strong>Refund and Cancellation Policy:</strong>
                            </p>
                            <p>Letstalk offers products and services for purchase within the App, known as "In-App
                                Purchases." By opting to make such purchases, you acknowledge and agree that
                                additional terms, presented to you at the time of purchase, may apply and are
                                considered part of these terms.</p>
                            <p>You can make an In-App Purchase using the following payment methods:</p>

                            <ul>
                                <li> Through a third-party platform such as the Apple App Store and Google Play Store
                                    ("Third Party Store").</li>
                                <li> By using your credit card, debit card, or PayPal account, with payment processed by
                                    a third-party processor.
                                </li>
                            </ul>
                            <p>Upon making an In-App Purchase, you authorize us to charge your selected Payment
                                Method. If payment is not received from your chosen Payment Method, you agree to
                                promptly settle all outstanding amounts upon our request.</p>

                            <p><b>1.</b> Users may request a refund for any wallet loads made on the App within 7 days of the
                                transaction date. Refund requests must be submitted via email to
                                <a href="mailTo:support@letstalk.com">support@letstalk.com</a> and include the user's name, transaction details, and reason for
                                the refund.</p>
                            <p><b>2.</b> Refunds will be processed within 30 days of receiving the refund request. Letstalk
                                reserves the right to refuse refunds for transactions deemed fraudulent or violating the
                                App's terms and conditions.</p>

                            <p><b>3.</b> Users may cancel their account and delete their personal information from the App
                                anytime by contacting customer support. Upon account cancellation, any remaining
                                wallet balance will be forfeited.</p>

                            <p><b>4.</b> Letstalk reserves the right to modify or amend this Refund and Cancellation Policy at
                                any time without prior notice. Any changes will be effective immediately upon posting
                                to the App.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}