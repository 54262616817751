import React from "react";
import Mask3 from '../../Asstes/images/new/Mask group3.png';
import Group1 from '../../Asstes/images/new/Group1.png';
import Vector from '../../Asstes/images/new/Vector 3.png';
import './FAQs.css';

const FAQs = () => {
    return (
        <>
            <section className="FAQs_container" id="FAQs_section">
                <div className="container">
                    <div className="FAQs_wraper d-flex flex-wrap align-items-start column-gap-0">
                        <div className="w-50 FAQs_Banner text-center">
                            <h3 className="text-left w-100"> Frequently Asked <br /> Questions</h3>
                            <img src={Mask3} alt="" className="p-5" width={'100%'} />
                            <img src={Group1} alt="" className="" width={'70%'} />
                            {/* <img src={Vector} alt="" width={'40%'} />    */}
                        </div>
                        <div className="w-50 FAQs_Qua">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="btn accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What picture should I use for the profile avatar?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Upload photos to Letstalk that feature who everyone came to see: you! Ditch your friends because this isn’t about them and remove the sunglasses because they hide your face. The best pics are in focus; some say a smile goes a long way here.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How do Letstalk matches work?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Letstalk allows users to create profiles, browse through other profiles, and initiate conversations through audio calls and chat. Users can also send virtual gifts to express their appreciation or affection towards their friends.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Is Letstalk safe to use?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yes, safety is our top priority. We employ robust security measures to protect user data and ensure a safe and secure browsing experience for all our members.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4">
                                            How does Letstalk work?
                                        </button>
                                    </h2>
                                    <div id="collapseThree4" class="accordion-collapse collapse" aria-labelledby="headingThree4" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Letstalk connects you with profiles using location-based technology based on the gender, distance, and orientation filters you set.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5" aria-expanded="false" aria-controls="collapseThree5">
                                         Are Letstalk profiles real?
                                        </button>
                                    </h2>
                                    <div id="collapseThree5" class="accordion-collapse collapse" aria-labelledby="headingThree5" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Letstalk offers Photo Verification to make sure the person you’re talking to matches their photos. Verified profiles will have a blue checkmark and we strongly suggest you get one—they look good on everyone and help keep our community safe.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree6">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6" aria-expanded="false" aria-controls="collapseThree6">
                                            How much does it cost to use Letstalk?
                                        </button>
                                    </h2>
                                    <div id="collapseThree6" class="accordion-collapse collapse" aria-labelledby="headingThree6" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        While downloading the app is free, Letstalk operates on a nominal platform fee, which users can pay by loading their wallet with their preferred amount. Additionally, we offer cashback promotions on wallet loads to provide added value to our users.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree7">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree7" aria-expanded="false" aria-controls="collapseThree7">
                                        Can I use Letstalk to meet people from  <br /> different states?
                                        </button>
                                    </h2>
                                    <div id="collapseThree7" class="accordion-collapse collapse" aria-labelledby="headingThree7" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        Absolutely! Letstalk is designed to connect individuals from across the country. Whether you're looking to learn about different cultures or simply make new friends from diverse backgrounds, our platform welcomes users from all over the country.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree8">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree8" aria-expanded="false" aria-controls="collapseThree8">
                                        Are there any restrictions on the types of <br /> conversations allowed on Letstalk?
                                        </button>
                                    </h2>
                                    <div id="collapseThree8" class="accordion-collapse collapse" aria-labelledby="headingThree8" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            While we encourage respectful and meaningful interactions, we do have community guidelines in place to ensure that all conversations adhere to our standards of conduct. Any inappropriate or offensive behavior is strictly prohibited, and users found violating our guidelines may face account suspension or termination.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree9">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree9" aria-expanded="false" aria-controls="collapseThree9">
                                        How does the gifting feature work?
                                        </button>
                                    </h2>
                                    <div id="collapseThree9" class="accordion-collapse collapse" aria-labelledby="headingThree9" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        The gifting feature allows users to express their appreciation or affection towards their friends by sending virtual gifts such as roses, chocolates, bouquets, or rings during chat sessions.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree10">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree10" aria-expanded="false" aria-controls="collapseThree10">
                                        What measures are in place to ensure <br />user safety and confidentiality?
                                        </button>
                                    </h2>
                                    <div id="collapseThree10" class="accordion-collapse collapse" aria-labelledby="headingThree10" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        At Letstalk, we prioritize user privacy and security. We utilize 128-bit encryption for chatting and adhere to stringent safety protocols to safeguard our users' personal information.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree11">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree11" aria-expanded="false" aria-controls="collapseThree11">
                                         Are there any advertisements on <br /> Letstalk?
                                        </button>
                                    </h2>
                                    <div id="collapseThree11" class="accordion-collapse collapse" aria-labelledby="headingThree11" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        No, Letstalk is completely ad-free. We do not promote or sponsor any products or services to ensure a hassle-free and enjoyable experience for our users.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQs;