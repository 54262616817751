import React from "react";
import './about.css';
import LetsChat from '../../Asstes/images/new/Group 887.png';
import Vector3 from '../../Asstes/images/new/Vector 3.png';
import Group940 from '../../Asstes/images/new/Group 940.png';
import Group946 from '../../Asstes/images/new/Group 946.png'
import Group931 from '../../Asstes/images/new/Group 931.png'
import Group932 from '../../Asstes/images/new/Group 932.png'
import Group933 from '../../Asstes/images/new/Group 933.png'
import Group935 from '../../Asstes/images/new/Group 935.png'

const About = () => {
    return (
        <>
            <section className="About_section_container" id="about_section">
                <div className="container">
                    <div className="about_container w-100 d-flex flex-wrap align-items-center column-gap">
                        <div className="w-50 about_img text-center">
                            <img src={LetsChat} alt="Lets Chat" width={'80%'} />
                            <img src={Vector3} alt="Lets Chat" width={'30%'} />
                        </div>
                        <div className="w-50 about_content">
                            <h3>About Us</h3>
                            <p>Welcome to Friends Chat - Your Ultimate Destination for Meaningful Connections!</p>

                            <p className="text-justify">At Friends Chat, we're more than just a social networking platform; we're a vibrant community dedicated to fostering genuine friendships and facilitating enriching interactions among individuals countrywide. In today's fast-paced digital age, where superficial connections often prevail, we believe in the power of authentic human connections to uplift and inspire.</p>
                        </div>
                    </div>
                
                    <div class="gallery About_container1">
                        <div class="gallery__item">
                            <div className="about_card position-relative p-3">
                                <img src={Group940} alt="group" width={'80px'} />
                                <p></p>
                                <h4>Our Vision</h4>
                                <p>Our vision at Friends Chat is to create a world where geographical boundaries and cultural differences are no longer barriers to meaningful relationships. We envision a platform where individuals can come together, irrespective of background or beliefs, to share experiences, learn from one another, and forge lasting bonds that transcend virtual spaces</p>
                                {/* <img src={Group946} alt="group" className=" position-absolute bottom-0" width={'30px'} /> */}
                            </div>
                        </div>
                        <div class="gallery__item">
                            <div className="about_card position-relative p-3">
                                <img src={Group932} alt="group" width={'80px'} />
                                <p></p>
                                <h4>Innovation and Safety</h4>
                                <p>Driven by innovation and guided by a commitment to user safety, Friends Chat employs cutting-edge technology to ensure a secure and seamless experience for all users. Our use of 128-bit encryption for chatting guarantees the confidentiality of your conversations, while our stringent safety protocols provide peace of mind in an online environment.</p>
                                {/* <img src={Group946} alt="group" className=" position-absolute bottom-0" width={'30px'} /> */}
                            </div>
                        </div>
                        <div class="gallery__item gallery__item--v-2">
                            <div className="about_card position-relative p-3">
                                <img src={Group931} alt="group" width={'80px'} />
                               
                                <p></p>
                                 <h4>Empowering Connections</h4>
                                 <p>Friends Chat is designed to empower users to connect with others on a deeper level. Whether you're seeking companionship, intellectual discourse, or simply a listening ear, our platform provides the tools and resources to facilitate genuine interactions. From engaging in thought-provoking conversations to sharing moments of laughter and joy, Friends Chat is where meaningful connections are made.</p>
                                
                                {/* <img src={Group946} alt="group" className=" position-absolute bottom-0" width={'30px'} /> */}
                            </div>
                        </div>
                        <div class="gallery__item">
                            <div className="about_card position-relative p-3">
                                <img src={Group933} alt="group" width={'80px'} />
                                <p></p>
                                <h4>Community-Centric Approach</h4>
                                <p>At the heart of Friends Chat lies our vibrant community of users who embody diversity, inclusivity, and mutual respect. We believe in fostering a supportive and welcoming environment where every voice is heard and valued. Whether you're a seasoned social butterfly or a newcomer to the world of online networking, you'll find a home at Friends Chat.</p>
                                {/* <img src={Group946} alt="group" className=" position-absolute bottom-0" width={'30px'} /> */}
                            </div>
                        </div>
                        <div class="gallery__item">
                            <div className="about_card position-relative p-3">
                                <img src={Group935} alt="group" width={'80px'} />
                                <p></p>
                                <h4>Our Promise</h4>

                                <p>When you join Friends Chat, you're not just joining a platform – you're becoming part of a national network of like-minded individuals who share your passion for connection and camaraderie. With our user-friendly interface, ad-free environment, and an array of exciting features such as audio calls, chat, and virtual gifting, we're committed to enhancing your online experience and helping you forge meaningful relationships that last a lifetime.</p>
                                {/* <img src={Group946} alt="group" className=" position-absolute bottom-0" width={'30px'} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;