import React from "react";
import './call.css';
import '../ChatAcrossDevices/ChatAcrossDevices.css';
import '../slider/slider.css';
import PlayStore from '../../Asstes/images/new/googleplay.png';
import AppStore from '../../Asstes/images/new/appstore.png';
import Vector6 from '../../Asstes/images/new/Vector 6.png';
import Vector8 from '../../Asstes/images/new/Vector 8.png';
import Group1 from '../../Asstes/images/new/Group1.png';
import Group943 from '../../Asstes/images/new/Group 943.png';
import Group944 from '../../Asstes/images/new/Group 944.png';
import Group945 from '../../Asstes/images/new/Group 945.png';

const Call = () => {
    return (
        <>
            <section className="call_container">
                <div className="container">
                    <div className="calls_warrper d-flex flex-wrap">
                        <div className="call_container2 d-flex align-items-start column-gap-3">
                            <div className="w-60 chat_content mt-5">
                                <h3 className="fs-3 text-black"> Call </h3>
                                <p className="fs-6 text-black"> High quality calls & no logs </p>
                                <img src={Group943} alt="" className="mob_480 p-3 " width={'100%'} />
                                <div className="w-100 mt-3">
                                    
                                    <div class="w-100 index-module__container--WJ2DC d-block">
                                        <div className="w-100 d-flex column-gap-3 mt-5">
                                            <div class="index-module__iconLayout--tuQHv mb-3">
                                                <img src={PlayStore} alt="" width={'100%'} />
                                            </div>
                                            <div class="index-module__iconLayout--tuQHv">
                                                <img src={AppStore} alt="" width={'100%'} />
                                            </div>
                                        </div>
                                        <div class="index-module__iconLayout--tuQHvg mt-5">
                                            <img src={Vector6} alt="" className="mt-5" width={'70%'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="call_container1 call_services p-5 desk_top">
                            <img src={Group943} alt="" width={'100%'} />
                        </div>

                    </div>
                    <div className="calls_warrper d-flex flex-wrap more_fun_container">
                        <div className="call_container1 call_services p-5 desk_top">
                            <img src={Group944} alt="" width={'100%'} />
                        </div>
                        <div className="call_container2 d-flex align-items-center justify-content-center">
                            <div className="w-60 chat_content mt-5">
                                <h3 className="fs-3 text-black"> More Fun In Chats </h3>
                                <p className="fs-6 text-black"> Customize messages & express yourself </p>
                                <img src={Group944} alt="" className=" mob_480 mt-4" width={'100%'} />
                                <div className="w-100 mt-3">
                                    <div class="w-100 index-module__container--WJ2DC d-block mt-5">
                                        <div className="w-100 d-flex align-items-end column-gap-3">
                                            <div class="index-module__iconLayout--tuQHv">
                                                <img src={PlayStore} alt="" width={'100%'} />
                                            </div>
                                            <div class="index-module__iconLayout--tuQHv">
                                                <img src={AppStore} alt="" width={'100%'} />
                                            </div>
                                        </div>
                                        <div class="index-module__iconLayout--tuQHvw mt-5">
                                            <img src={Vector6} alt="" className="mt-5" width={'70%'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call_container call_container_bg">
                <div className="container">
                    <div className="calls_warrper d-flex flex-wrap">
                        <div className="call_container2 d-flex align-items-start column-gap-3">
                            <div className="w-60 chat_content mt-5">
                                <h3 className="fs-3 text-black"> Discover Social Life </h3>
                                <p className="fs-6 text-black"> Expand Your Social Network </p>
                                <img src={Group945} alt="" className="mt-3 mob_480" width={'100%'} />
                                <div className="w-100 mt-3">
                                    <div class="w-100 index-module__container--WJ2DC d-block">
                                        <div className="w-100 d-flex column-gap-3 mt-5">
                                            <div class="index-module__iconLayout--tuQHv">
                                                <img src={PlayStore} alt="" width={'100%'} />
                                            </div>
                                            <div class="index-module__iconLayout--tuQHv">
                                                <img src={AppStore} alt="" width={'100%'} />
                                            </div>
                                        </div>
                                        <div class="index-module__iconLayout--tuQHvg mt-5">
                                            <img src={Vector8} alt="" className="mt-5" width={'70%'} />
                                            <img src={Group1} alt="" className="mt-5" width={'70%'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="call_container1 call_services p-5 desk_top">
                            <img src={Group945} alt="" width={'100%'} />
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Call;