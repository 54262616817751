import React from 'react';
import {Link, useNavigate} from 'react-router-dom'

export default function LiItems({menudata}) {
    const navigate = useNavigate();
    return (
        <> 
        {menudata.map((item, i) => <li key={i} className={item.subCatMenu.length === 0 ? 'menu_link':"menu_link dropdown-toggle"}>
        <a href={item.url} className='nav-link'>
             {item.catMenu}
             {item.subCatMenu.length === 0 ? "" : <ul className='w-100 menu_inner_container sub-menu_list'>
                {
                    item.subCatMenu.map(items => <li> <a href={items.url} className='nav-link'> {items.title} </a></li>)
                }
            </ul>}
            </a>
        </li>)}
        </>
    )
}





