import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Home from '../../Assets/amni/home.png';
// import About from '../../Assets/Group41.svg';
// import FAQ from '../../Assets/Path123.svg';
// import Contact from '../../Assets/Path124.svg';
// import Products from '../../Assets/product.svg';
// import Arrow from '../../Assets/amni/right-arrow.png';
// import User from '../../Assets/amni/person.png';


export default function MobHeader() {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="mobHeader" onClick={handleShow}>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header className="">
                    <div className='d-block'>
                        <span onClick={handleClose} style={{fontSize:'50px'}}>&#8592;</span>
                    </div>
                    {/* <div className='p-5 d-flex align-items-center justify-content-start column-gap-5'>
                        <img src={''} alt="" width={'35px'} className='' />
                        <h6 className="m-0"> Hi user </h6>
                    </div> */}
                </Modal.Header>
                <Modal.Body className='overflow-scroll'>
                    <div className='mobmanu'>
                        <ul className="manu">
                            <a href="#home" onClick={handleClose}>
                                <li className="nav_link">
                                    <img src={''} alt="" width={'20px'} />
                                    Home
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </a>

                            <a href="#about_section" onClick={handleClose}>
                                <li className="nav_link">
                                    <img src={''} alt="" />
                                    About Us
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </a>

                            <a href="#FAQs_section" onClick={handleClose}>
                                <li className="nav_link">
                                    <img src={''} alt="" width="20px" />
                                    FAQs
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </a>

                            <Link to="/privacy-policy">
                                <li className="nav_link">
                                    <img src={''} alt="" />
                                    Privacy Policy
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </Link>
                            <Link to="/terms">
                                <li className="nav_link">
                                    <img src={''} alt="" />
                                    Terms & Condition
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </Link>
                            <Link to="/cancellation-and-refund-policy">
                                <li className="nav_link">
                                    <img src={''} alt="" />
                                    Refund & Cancellation Policy
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </Link>
                            <Link to="/support">
                                <li className="nav_link">
                                    <img src={''} alt="" />
                                    Support
                                </li>
                                <img src={''} alt="" width={'15px'} />
                            </Link>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}