import React from "react";
import Header from "../components/commen-components/header/header";
import Slider from '../components/slider/slider';
import ChatAcrossDevices from '../components/ChatAcrossDevices/ChatAcrossDevices'
import Call from "../components/Calls/calls";
import Howtoinstall from "../components/howtoinstall/howtoinstall";
import Safety from "../components/safety/safety";
import About from "../components/About/about";
import FAQs from "../components/FAQs/FAQs";
import Footer from "../components/footer/footer";

export default function Home(){
    return(
        <>
            <Header />
            <Slider />
            <Howtoinstall />
            <ChatAcrossDevices />
            <Safety />
            <Call />
            <About />
            <FAQs />
            <Footer />
        </>
    )
}