import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom'
import Home from './screens/Home';
import Term from './screens/term';
import PrivacyPolicy from './screens/privacypolicy';
import RefundAndCancellation from './screens/refund';
import Support from './screens/support';

function App() {
  return (
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/support' element={<Support />} />
        <Route path='/terms' element={<Term />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/cancellation-and-refund-policy' element={<RefundAndCancellation />} />
    </Routes>
  );
}

export default App;
