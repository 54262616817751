import React, { useState } from "react";
import SupportImg from '../Asstes/images/customer-service.png';
import Header from "../components/commen-components/header/header";
import Footer from "../components/footer/footer";
import axios from "axios";

export default function Support() {
    const [state, setState] = useState({
        name: '',
        phone: '',
        email: '',
        concern: ''
    })

    const handleOnChnage = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value
        })
    }

    const OnSubmitHandle = (e) => {
        e.preventDefault();
        if (state.name === '' ||
            state.phone === '' ||
            state.email === '' ||
            state.concern === ''
        ) {
            alert('All fields are mandatory*.')
        } else {

            var url = 'http://14.99.239.244:8080/FlexPlatformFriendsChat/supportInfo';
            const body = {
                email: "sumit.ubarri@gmail.com",
                name: "sumit",
                mobile: "9199999999",
                concern: "test"
            }
            axios.post()

            alert('Your query has been submitted successfully.')
            setState({
                name: '',
                phone: '',
                email: '',
                concern: ''
            })
        }
    }

    return (
        <>
            <Header />
            <div className="support_container pb-5 mt-5 main-container">
                <div className="container">
                    <div className="content-container text-center w-100 mb-5">
                        <h1> Support Center </h1>
                        <h3> Where Every Concern Matters </h3>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-center mb-5">
                        <div className="w-75 support_wrapper d-flex flex-wrap border">
                            <div className="w-50 support_img d-flex align-items-center justify-content-center p-5">
                                <img src={SupportImg} alt="support" width="70%" />
                            </div>
                            <div className="w-50 support_form d-flex align-items-center">
                                <form className="w-100" onSubmit={OnSubmitHandle}>
                                    <div class="form-group">
                                        <input type="text" onChange={handleOnChnage} class="form-control" id="Name" placeholder="Name*" name="name" required value={state.name} />
                                    </div>
                                    <div class="form-group">
                                        <input type="number" onChange={handleOnChnage} class="form-control" id="Mobile" name="phone" minlength="10" maxlength="10" required size="10" placeholder="Mobile Number*" pattern="/^-?\d+\.?\d*$/" value={state.phone} />
                                    </div>
                                    <div class="form-group">
                                        <input type="email" onChange={handleOnChnage} class="form-control" id="Email" placeholder="Email Id*" name="email" required value={state.email} />
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" onChange={handleOnChnage} id="Concern" placeholder="Concern*" value={state.concern} name="concern" required></textarea>
                                    </div>
                                    <div class="form-group mb-0">
                                        <button type="submit" class="btnSubmit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}