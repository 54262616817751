import React from "react";
import './ChatAcrossDevices.css';
import '../slider/slider.css';
import PlayStore from '../../Asstes/images/new/Group 884.png';
import AppStore from '../../Asstes/images/new/Group 881.png';
import Group880 from '../../Asstes/images/new/Group 880.png';
import LetsTalk from '../../Asstes/images/new/Group 887.png';


const ChatAcrossDevices = () => {
    return (
        <>
            <section className="chatAcross_contaner">

                <div className="container11">
                    <div className="chat_Container">
                        <div className="chat_Across_devices1 d-flex align-items-end">
                            <div className="chat_content">
                                <div className="w-100 mt-5 p-5">
                                <img src={LetsTalk} alt="" className="" width={'100%'} />
                                    <button className="btn lets_btn mt-4"> Let's Meet. Let's Talk. Let's Get Entertained </button>
                                </div>
                            </div>
                        </div>
                        <div className="chat_Across_devices position-relative">
                            <div className="chat_first_bg">
                                <img src={AppStore} alt="icon" className="first_img" width={'140px'} height={'140px'} />
                            </div>
                            <div className="chat_second_bg">
                                <img src={PlayStore} alt="icon" className="first_img" width={'130px'} />
                            </div>
                            <div className="chat_threed_bg">
                                <img src={Group880} alt="icon" className="first_img" width={'180px'} />
                            </div>
                            <div className="chat_fourth_bg"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChatAcrossDevices;