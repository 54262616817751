import React, { useEffect } from 'react'
import Logo from '../../../Asstes/images/new/Group 73.png';
import './header.css'
// import Call from '../Assets/images/phone-call.png';
// import Email from '../Assets/images/email.png';
// import Address from '../Assets/images/pin.png';
// import Facebook from '../Assets/images/facebook.png';
// import Twitter from '../Assets/images/twitter.png';
// import Google from '../Assets/images/google-plus.png';
// import Insta from '../Assets/images/instagram.png';

import LiItems from './LiItems';
import MobHeader from './MobileHeader';
// import SubMenu from './subMenu';
export default function Header() {

    const menudata = [
        {
            catMenu: 'Home',
            url: '#home',
            subCatMenu: []
        },
        {
            catMenu: 'About us',
            url: '#about_section',
            subCatMenu: []
        },
        {
            catMenu: 'FAQs',
            url: '#FAQs_section',
            subCatMenu: []
        },
        {
            catMenu: 'Download App',
            url: '#home',
            subCatMenu: []
        }
    ]

    const [scrollPosition, setScrollPosition] = React.useState(0);
const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);
    return (
        <>
            <header>
                <div className='header_container'>
                    {/* <div className='top_header_container border-1'>
                        <div className='container d-flex justify-content-between'>
                            <div className='contact_details_container d-flex aling-items-center justify-content-start w-60 column-gap-4'>
                                <div className='icon_box d-flex align-items-center column-gap-2'>
                                    <img src={Call} alt='call' width={'15px'} height={'15px'} />
                                    <span> +918800870482 </span>
                                </div>
                                <div className='icon_box d-flex align-items-center column-gap-2'>
                                    <img src={Email} alt='call' width={'15px'} height={'15px'} />
                                    <span> info@example.com </span>
                                </div>
                                <div className='icon_box d-flex align-items-center column-gap-2'>
                                    <img src={Address} alt='call' width={'15px'} height={'15px'} />
                                    <span> 1010 Avenue, NY, USA </span>
                                </div>
                            </div>
                            <div className='social_details_container d-flex w-40 justify-content-end align-items-center'>
                                <ul className='d-flex align-items-center column-gap-4 p-0 m-0'>
                                    <li> <span className='fw-medium'> Follow : </span></li>
                                    <li><img src={Facebook} alt='facebook' width={'15px'} height={'15px'} /></li>
                                    <li><img src={Twitter} alt='twitter' width={'15px'} height={'15px'} /></li>
                                    <li><img src={Insta} alt='insta' width={'15px'} height={'15px'} /></li>
                                    <li><img src={Google} alt='google' width={'15px'} height={'15px'} /></li>

                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className='main_header_container'>
                        <div className='container'>
                            <div className='main_menu_container d-flex align-items-center column-gap-5 p-3'>
                                <div className='logo_container'>
                                    <a href={'/'}>
                                        <img src={Logo} alt='A1degitalhub.com is logo' width={'100px'} /> </a>
                                </div>
                                <div className='menu_container w-75 d-flex align-items-center justify-content-end'>
                                    <div className='mob_480'>
                                        <MobHeader />
                                    </div>

                                    <ul className='menu_inner_container desk_top w-100 d-flex align-items-center justify-content-end column-gap-4 m-0'>

                                        <LiItems menudata={menudata} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='sub_menu_container'>
                            <div className='container'>
                                <ul className='menu_inner_container w-100 d-flex align-items-center justify-content-end column-gap-4 m-0'>
                                    {/* <SubMenu menudata ={menudata1} /> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {scrollPosition >= 500 ? <button className='whatsApp_btn position-fixed rounded-bottom rounded-top btn bg-light shadow' onClick={()=> window.scrollTo({top:0,behavior:"smooth"})}>
                    {/* {scrollPosition} */}
                    <span class="fa fa-whatsapp" aria-hidden="true"> &#129029;</span>
                </button>:null}

            </header>
        </>
    )
}