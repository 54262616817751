import React from "react";
import img1 from '../../Asstes/images/new/Group 870.png';
import img2 from '../../Asstes/images/new/Group 871.png';
import img3 from '../../Asstes/images/new/Group 872.png';
import './howtoinstall.css'

const Howtoinstall = () =>{
    const HowToinstal = [
        {
            title:'Follow 3 Step To Start Chats and Call',
            img:img1
        },
        {
            title:'Wait For Download To Finish',
            img:img2
        },
        {
            title:'Launch The App',
            img:img3
        }
    ]
    return(
        <>
            <section className="howToInstall_section">
                <div className="container">
                    <div className="head_contect p-5">
                        <div className="heading text-center">
                            <h3> How to Install </h3>
                            <p className="fa-6">Follow 3 Step To Start Chats and Call</p>
                        </div>
                    </div>
                    <div className="HowToInstal_container d-flex align-items-center column-gap-2 justify-content-around flex-wrap">
                        {
                            HowToinstal.map((item,i) => <div className="card_instal d-flex align-items-center column-gap-4">
                            <img src={item.img} alt={item.title} width={'60px'} />
                            <p>{item.title}</p>
                        </div>)
                        }
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Howtoinstall;