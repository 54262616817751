import React, { useEffect } from "react";
import './alert.css';
import $ from 'jquery';

export default function CustomAlert({title,text}) {

    const CustomAlert = new function () {
        this.show = function (msg) {
            let content = document.getElementById('dialog-body');
            $('.alert_container').css('display','block');
            $('#dialog-container').css('top', '48%');
            $('#dialog-container').css('opacity', 1);
            $('#dialog-container').css('transform', 'translate(-50%, -60px)');
            content.textContent = msg;
        }
        this.close = function () {
            $('.alert_container').css('display','none');
            $('#dialog-container').css('top', '-30%');
            $('#dialog-container').css('opacity', 0);
        }
    }

    useEffect(() => {
        CustomAlert.show(text)
    },[])

    return (
        <>
            {/* <div class="button-group">
                <button onClick={() => CustomAlert.show('This is our alert dialog. Press \'Ok\' to close this.')}>Custom Button</button>
            </div> */}
            <div className="alert_container">
                <div id="dialog-container" class="dialog-container">
                    <div class="dialog-header">{title}</div>
                    <div id="dialog-body" class="dialog-body">{text}</div>
                    <div class="dialog-footer">
                        <a onClick={() => CustomAlert.close()}>Ok</a>
                    </div>
                </div>
            </div>
        </>
    )
}