import React from "react";
// import GooglePlay from '../../Asstes/images/download.png'
// import AppStore from '../../Asstes/images/appstor.png'
import Logo from '../../Asstes/images/new/Group 73.png';
import Facebook from '../../Asstes/images/new/image 8.png';
import Insta from '../../Asstes/images/new/image 6.png';
import Snap from '../../Asstes/images/new/image 7.png';
import Moz from '../../Asstes/images/new/image 5.png';
import Josh from '../../Asstes/images/new/image 9.png';
import './footer.css';
const Footer = () => {
    return(
        <>
            <section className="footer_section">
                <div className="container">
                    <div className="footer_container d-flex align-items-start column-gap-5 flex-wrap">
                        <div className="w-30 letsChat_about_container text-white">
                            <h3> Join the Letstalk Community Today </h3>
                            <p className="text-justify mt-4">Discover the joy of authentic connections and unlock a world of possibilities with Letstalk. Whether you're looking to make new friends, explore different cultures, or simply enjoy stimulating conversations, we invite you to join us on this incredible journey of friendship and discovery. Download the Letstalk app now and embark on an adventure of connection, growth, and endless possibilities.</p>
                        </div>
                        {/* <div className="w-20"></div> */}
                        <div className=" w-20 Quick_Links">
                            <h3 className="text-white">Quick Links</h3>
                            <ul className="list-unstyled text-white mt-5">
                                <li> <a href="#about_section">About Us </a></li>
                                <li><a href="/privacy-policy">Privacy Policy </a></li>
                                <li> <a href="/terms">terms & Condition </a></li>
                                <li> <a href="/cancellation-and-refund-policy">Refund & Cancellation Policy</a></li>
                                <li> <a href="/support">Support</a></li>
                            </ul>
                            {/* <p>Or Scan <strong>QR CODE </strong> </p>
                            <img src={GooglePlay} className="google" alt="Google Play Store " width={'200px'}/> */}
                        </div>

                        <div className="w-30 letsChat_logo">
                            <p></p>
                            <img src={Logo} alt="logo" className="logo" width={'100%'} />
                            <ul className="w-100 p-0 justify-content-center list-unstyled d-flex align-items-center column-gap-3 text-white mt-5">
                                <li> <a href="/"><img src={Facebook} alt="facebook" width={'50px'} /> </a></li>
                                <li><a href="/"><img src={Insta} alt="Instagram" width={'50px'} /></a></li>
                                <li><a href="/"><img src={Snap} alt="snap Chat" width={'50px'} /></a></li>
                                <li><a href="/"><img src={Moz} alt="Moz" width={'50px'} /></a></li>
                                <li><a href="/"><img src={Josh} alt="Josh" width={'50px'} /></a></li>
                            </ul>
                            {/* <img src={AppStore} className="appstore" alt="logo" width={'200px'} /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;