import React, { useState } from "react";
import './slider.css';
import banner from '../../Asstes/images/new/Girls 1.png';
import Wallet from '../../Asstes/images/new/Group 81.png';
import Confidentiality from '../../Asstes/images/new/Group 80.png';
import Encryption from '../../Asstes/images/new/Layer_1.png';
import PlayStore from '../../Asstes/images/download.png';
import AppStore from '../../Asstes/images/appstor.png';
import $ from 'jquery'
import CustomAlert from "../commen-components/alert";

const Slider = () => {
    const [state, setState] = useState('');

    const isNumberKey = (e) => {
        if(e.target.value !== ''){
            // console.log(e.target.value);
            setState(e.target.value)
            $('.invalid').css('display','none');
        }else console.error(e.target.value);
    }

    const sendAppLink = (e) => {
        e.preventDefault();
        if(state !== ''){
            console.log(state);
            $('.invalid').css('display','none');
            return <CustomAlert text='' title="" />
        }else $('.invalid').css('display','block');
        
    }

console.log(state);
    return (
        <>
            <section className="first_slide_container" id="home">
                <div className="container">
                    <div className="w-100 slide_wraper d-flex overflow-hidden">
                        <div className="w-60 app_details position-relative">
                            <div class="app-sms m_hide">
                                <div class="advanced_banner_option">
                                    <span class="top_blue_banner_heading">Welcome to </span>
                                    <p class="light_blue_heading">
                                        #Letsmakefriend
                                    </p>
                                    <span class="bottom_blue_banner"><strong>Where Connections Blossom</strong></span>
                                    <p className="card-text">#Unlimited Entertainment with Boundless Possibilities</p>
                                    <p className="card-text">Let's Get Connected  for Loving Companions</p>
                                    
                                </div>

                                <div class="sms_section">
                                    <div class="get_app_sms">
                                        <div class="digit">
                                            <input class="phone-number phoneNum" onChange={(e) => isNumberKey(e)} maxlength="10" pattern="[6-9]{1}[0-9]{9}" type="text" />

                                            <button onClick={sendAppLink} class="get-app-btn">Get App link SMS</button>
                                        </div>
                                        <label class="invalid fs-6">Please Enter a Valid Phone Number!</label>
                                        <div class="message"><div class="message_read"></div></div>
                                    </div>
                                </div>
                                <p class="qr-section mt-5 mb-4">
                                    <span><strong>or</strong> Scan the QR Code</span>
                                </p>

                                <div className="d-flex column-gap-4 align-items-center">
                                    <div class="index-module__container--WJ2DC">
                                        <div class="index-module__iconLayout--tuQHv">
                                            <img src={PlayStore} alt="" width={'150px'} />
                                        </div>
                                        <div class="index-module__iconLayout--tuQHv">
                                            <img src={AppStore} alt="" width={'150px'} />
                                        </div>
                                    </div>
                                </div>
                                    <div class="bottom-info mt-5">
                                        <div class="instant_withdraw d-flex">
                                            <div class="inner_set">
                                                <div class="col col1">
                                                    <img src={Confidentiality} alt="Instant Withdrawals" width="34" height="34" sizes="(max-width: 24px) 100vw, 24px" />
                                                    <span>100% <br /> Confidentiality Guarantee </span>
                                                </div>
                                                <div class="col col2">
                                                    <img src={Encryption} alt="100% Legal &amp; Safe" width="34" height="34" sizes="(max-width: 24px) 100vw, 24px" />
                                                    <span>128-bit <br />encryption</span>
                                                </div>
                                                <div class="col col3">
                                                    <img src={Wallet} alt="Handcrafted By Paytm" width="34" height="34" sizes="(max-width: 34px) 100vw, 34px" />
                                                    <span>Cashback on<br /> Wallet load</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-40 img_container">
                                <img src={banner} alt="banner" width={'100%'} height={'500'} />
                                <spna className='img1container'></spna>
                            </div>
                        </div>
                    </div>
            </section >
        </>
    )
}

export default Slider;