import React from "react";
import '../ChatAcrossDevices/ChatAcrossDevices.css';
import '../slider/slider.css';
import './safety.css';
import Group936 from '../../Asstes/images/new/Group 942.png';


const Safety = () => {
    return (
        <>
            <section className="chatAcross_contaner safety_container">

                <div className="container11">
                    <div className="chat_Container">
                        <div className="chat_Across_devices position-relative">
                            <h3 className="mt-5"> No Language <br />Barriers</h3>
                            <div className="chat_fourth_bg d-flex align-items-center column-gap-3 flex-wrap mt-5 row-gap-3">
                                <button className="hindibtn"> Hindi </button>
                                <button className="hindibtn btn_00B37C"> English </button>
                                <button className="hindibtn btn_02AFF7"> Marathi </button>
                                <button className="hindibtn btn_F23D63"> Punjabi </button>
                                <button className="hindibtn btn_02AFF7"> Gujrati </button>
                            </div>
                        </div>
                        <div className="chat_Across_devices1 d-flex align-items-center">
                            <div className="chat_content">
                                <div className="safety_esx d-flex flex-wrap align-items-center column-gap-4">
                                    <div className="safety_content">
                                        <h3 className="text-white mt-5"> Safety First For <br /> All Users</h3>
                                        <ul className="list-unstyled text-white">
                                            <li> &#10003; No Obscenity</li>
                                            <li> &#10003; No Fake ID</li>
                                            <li> &#10003; Enjoy Anonymity</li>
                                            <li> &#10003; Blocking Option</li>
                                            <li> &#10003; No ScreenShots</li>
                                        </ul>
                                    </div>
                                    <div className="safety_img mt-5">
                                        <img src={Group936} alt="" className="mt-5" width={'100%'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Safety;