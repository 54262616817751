import React from "react";
// import { withRouter } from "react-router-dom";
// import "../styles/about.css";
import Header from "../components/commen-components/header/header";
import Footer from "../components/footer/footer";
// import axios from 'axios';

class TC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: null,
            policyData: {}
        };
    }


    render() {
        // const { displaySignupPopUp, loginSelected } = this.state;
        return (
            <>

                <Header />
                <div class="container">
                    <div class="row page-body mb-5">
                        <div class="col-sm-12 col-md-12 col-lg-12 float-left padd-0 text-left">
                            <strong>
                                <p class="about-header mt-5">Terms & Conditions:</p>
                            </strong>
                            <div class="termsouter">
                                <p> Welcome to Letstalk’s Terms and Conditions of Use (these “Terms”). This is a contract between you and the Letstalk Group (as defined further below) and we want you to know your and our rights before you use the Letstalk website or application (“Letstalk” or the “App”). Please take a few moments to read these Terms before enjoying the App,because once you access, view, or use the App, you are going to be legally bound by these Terms (so probably best to read them first!).
                                </p>

                                <p> Please be aware that if you subscribe to services for a term (the “Initial Term”), then
                                    the terms of your subscription will be automatically renewed for additional periods of
                                    the same duration as the Initial Term at Letstalk’s then-current fee for such services
                                    unless you cancel your subscription. You should also note that these Terms contain
                                    provisions governing how claims that you and Letstalk Group have against each other
                                    are resolved. In particular, it contains an arbitration agreement that will, with limited
                                    exceptions, require disputes between us to be submitted to binding and final
                                    arbitration. You have a right to opt out of the arbitration agreement. If you do not opt
                                    out of the arbitration agreement, (1) you will only be permitted to pursue claims and
                                    seek relief against us on an individual basis only, and (2) you are waiving your right to
                                    seek relief in a court of law and to have a jury trial on your claims.</p>

                                <div>
                                    <p> <b>Terms</b></p>
                                    <p> By accessing www.letstalk.com, you are agreeing to be bound by these www.letstalk.com Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law. </p>

                                    <p><b>1. Rules to use:</b></p>
                                    <p> Before you can use the App, you will need to register for an account (“Account”). To create an Account you must:</p>
                                    <ul>
                                        <li>
                                            be at least 18 years old or the age of majority to legally enter into a ontract under the laws of your home country; and
                                        </li>
                                        <li>
                                            be legally permitted to use the App by the laws of your home country.
                                        </li>
                                    </ul>
                                    <p> Unfortunately, we cannot allow you to use another person’s Account or to share your
                                        Account with any other person without permission.</p>

                                    <p><b>2. Account Deletion:</b></p>
                                    <p>While we hope you'll thoroughly enjoy your time on Letstalk, if you decide to part ways,
                                        you can easily delete your Account whenever you wish. Simply log in and navigate to
                                        the "Side menu bar" page, then click on the 'Delete account' link. Your Account will be
                                        removed within three working days, although it may take some additional time for Your
                                        Content (as defined below) to be fully erased from the App. Rest assured, your profile
                                        information will be handled under our Privacy Policy. If you delete your Account and
                                        attempt to create a new one using the same credentials within this timeframe, we'll
                                        reactivate your Account for you.</p>

                                    <p><b>3. </b> We employ a blend of automated systems and a team of moderators to oversee
                                        and evaluate accounts and messages for any content that suggests violations of
                                        these Terms. We retain the right, at our sole discretion, to terminate or suspend
                                        any Account, limit access to the App, or utilize any available operational,
                                        technological, legal, or other means to uphold the Terms (including, but not
                                        limited to, blocking specific IP addresses), without any obligation to provide
                                        prior notice or liability. Without constraining the above statement, we explicitly
                                        reserve the right to terminate or suspend your Account without notice: (1) for
                                        breaching these terms, (2) based on your behavior on the App or interactions
                                        with other App users (including offline conduct), if we, at our sole discretion,
                                        determine such behavior to be inappropriate or improper, (3) if we or our
                                        affiliates, at our or their sole discretion, deem your conduct on other apps
                                        operated by our affiliates to be inappropriate or improper, or (4) for any reasons
                                        whatsoever that we, at our sole discretion, deem justifies termination. Should
                                        your Account be terminated or suspended, you acknowledge that you will not
                                        receive a refund for any paid services or features for which you have already
                                        been charged.
                                    </p>
                                    <p>You may not access, tamper with, or use non-public areas of the App or our systems.
                                        Certain portions of the App may not be accessible if you have not registered for an
                                        Account.</p>

                                    <p><b>4.The App provides access to three main types of content:</b></p>
                                    <ul>
                                        <li><strong>Your Content:</strong> This refers to the content you upload and provide.</li>
                                        <li><strong> Member Content: </strong> Content contributed by other members.
                                        </li>
                                        <li><strong>Our Content:</strong> Content provided by the Letstalk Group, including databases and software.</li>
                                    </ul>
                                    <p> Certain types of content are prohibited on Letstalk. While we aim to foster expression and diverse posting, restrictions are necessary for content that:</p>

                                    <ul>
                                        <li> Contains language or imagery that could be offensive, harassing, upsetting,
                                            embarrassing, alarming, or annoying to others. This includes language that may
                                            be discriminatory based on race, color, ethnicity, national origin, religion,
                                            disability, sexual orientation, gender expression, gender identity, or physical
                                            appearance.
                                        </li>
                                        <li> Is obscene, pornographic, violent, or otherwise undermines human dignity. This
                                            also extends to discriminatory language.</li>
                                        <li> Is abusive, insulting, threatening, or promotes racism, sexism, hatred, or
                                            bigotry, including discriminatory language.</li>

                                        <li>Encourages illegal activities such as terrorism or inciting racial hatred, or
                                            constitutes a criminal offense in itself.</li>
                                        <li> Is defamatory, libelous, or involves commercial activities like sales,
                                            competitions, advertising, or links to external websites.</li>
                                        <li> Involves the transmission of spam or junk mail.</li>
                                        <li> Contains malicious code designed to disrupt software, hardware, networks, or
                                            other equipment, or infringes upon privacy or intellectual property rights.
                                        </li>
                                        <li> Features another person without their consent.</li>
                                        <li> Is harmful to minors.</li>
                                        <li> Involves impersonation</li>
                                    </ul>
                                    <p>The Letstalk Group strictly enforces a zero-tolerance policy against such prohibited content.</p>

                                    <p><b>5.</b> You are responsible for maintaining the confidentiality of your account and
                                        password and for restricting access to your device to prevent unauthorized
                                        access to your account. You agree to accept responsibility for all activities that
                                        occur under your account or password.</p>

                                    <p><b>6.</b> You may not use the App in any manner that could damage, disable,
                                        overburden, or impair any Letstalk server, or the network(s) connected to any
                                        Letstalk server, or interfere with any other party's use and enjoyment of the App.</p>

                                    <p><b>7.</b> Letstalk reserves the right to terminate or suspend your account and access to
                                        the App at any time without prior notice or liability, for any reason whatsoever,
                                        including without limitation if you breach these Terms and Conditions.</p>

                                    <p><b>8.</b> Use License
                                        Permission is granted to temporarily download one copy of the materials (information
                                        or software) on Letstalk.com's website www.letstalk.com for personal, non-commercial
                                        transitory viewing only. This is the grant of a license, not a transfer of title, and under
                                        this license, you may not:</p>
                                    <ul>
                                        <li>modify or copy the materials;</li>
                                        <li>use the materials for any commercial purpose or for any public display
                                            (commercial or non-commercial);</li>
                                        <li>attempt to decompile or reverse engineer any software contained on letstalk's
                                            website www.letstalk.com;</li>
                                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                                        <li>transfer the materials to another person or "mirror" the materials on any other
                                            server</li>
                                        <li>This license shall automatically terminate if you violate any of these restrictions
                                            and may be terminated by Letstalk at any time. Upon terminating your viewing
                                            of these materials or upon the termination of this license, you must destroy any
                                            downloaded materials in your possession whether in electronic or printed format.</li>

                                    </ul>
                                    <p><b>9.</b> These Terms and Conditions shall be governed by and construed under the laws
                                        of India. Any dispute arising under these terms and conditions shall be subject to
                                        the exclusive jurisdiction of the courts of India.</p>

                                    <p><b>10.</b> If you have any questions or concerns about these Terms and Conditions, please
                                        contact us at <a href="mailTo:support@letstalk.com"> support@letstalk.com</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer history={this.props} />
            </>
        );
    }
}

export default TC;
